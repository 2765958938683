.checkbox__with-image {
    max-width: 250px;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 5px;
    width: fit-content;
    filter: grayscale(90%);
    opacity: .7;
    transition: all .2s ease;
    margin: 0 1rem;
    &.checked {
        opacity: 1;
        filter: grayscale(0);
        border-color: $primary-color;
    }
    .MuiFormControlLabel-root {
        margin: 0 !important;
    }
    .checkbox__with-image_wrapper {
        padding: 10%;
        .checkbox__image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .MuiCheckbox-root {
            display: none !important    // Hide classic checkbox field
        }
        .checkbox__with-image_label {
            text-align: center;
            display: block;
        }
    }
}
