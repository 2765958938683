.wrapper {
    padding: 15px;
    @media only screen and (min-width: 40rem) {
        padding: 20px;
    }
}
.wrapper:first-child,
.wrapper:last-child {
    height: 800px;
    @media only screen and (min-width: 40rem) {
        height: 500px;
    }
}
.loginContainer {
    height: 100%;
    // text-align: center;
}
.imgContainer {
    overflow: hidden;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 15px;
    img {
        width: 100%;
    }
}

.loginWelcomeContainer {
    position: relative;
    z-index: 10;
    top: 0;
    right: 0;
    max-width: 100%;
    max-height: 100vh;
    height: 100%;
    width: 100%;
    @media only screen and (min-width: 40rem) {
        max-height: 450px;
        position: absolute;
    }
    @media only screen and (min-width: 1200px) {
        max-width: 500px;
    }
}
.loginWelcomeInnerContainer {
    top: 0;
    right: 0px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    text-align: left;
    padding: 15px;
    border-radius: 10px;
    background-color: white;
    position: relative;
    z-index: 10;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
    margin: 0 auto;

    @media only screen and (min-width: 40rem) {
        position: absolute;
        max-height: 450px;
        padding: 30px;
    }
    @media only screen and (min-width: 1200px) {
        right: 20px;
        max-width: 500px;
    }
}
.loginWelcomeImageContainer {
    position: absolute;
    z-index: -5;
    bottom: 0;
    left: 0;
    display: none;
    @media only screen and (min-width: 1200px) {
        display: block;
    }
}
.loginWelcomeImageContainer img {
    rotate: -50deg;
    width: 100%;
    height: auto;
    margin-left: -240px;
    margin-bottom: 100px;
}
.loginformContainer {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 450px;
    text-align: center;
    padding: 70px 30px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
    @media only screen and (min-width: 1200px) {
        max-width: 500px;
    }
}
.form-control {
    display: block;
    height: 50px;
    margin-right: 0.5rem;
    text-align: center !important;
    font-size: 1.5rem !important;
    min-width: 0;
    padding: 17px;
    text-align: center !important;

    &:last-child {
        margin-right: 0;
    }
}
.form-control .MuiInputBase-input {
    text-align: center !important;
}
.blur {
    filter: blur(5px);
}
