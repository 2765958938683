@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&family=Source+Sans+3:wght@200;300;400&display=swap');
.navigation {
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    height: 40px;
    @media only screen and (min-width: 1200px ) {
        height: 90px;


    }
}
.logoContainer {
    background-color: white;
    // border: 1px solid black;
    width: 100px;
    height: 70px;
    border-radius: 50%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
    // margin-top: 20px;;
    @media only screen and (min-width: 1200px) {
        padding: 20px;
        margin-left: 80px;
        width: 220px;
        height: 150px;

    }
}
.logoContainer img {
    display: block;
    height: 80%;
    @media only screen and (min-width: 1200px) {
    }
}
.logoContainer p {
    display: block;
    text-align: center;
    font-family: "Source Sans 3", Arial, Helvetica, sans-serif;
    margin-top: 5px;
    font-size: 0.4rem;
    color: #e24511;
    @media only screen and (min-width: 1200px) {
        font-size: 0.8rem;
    }
}