.sidebarImgContainer {
    // width: 100%;
    height: 100%;
    margin-left: 20px;
    margin-right: 10px;
}
.sidebarImgContainer img {
    height: 100%;
}
.sidebarImgContainerSmall {
    // width: 100%;
    height: 10%;
    margin-left: 20px;
    margin-right: 10px;
}
.sidebarImgContainerSmall img {
    height: 40%;
}
.rotation {
    transform: rotate(50deg) scale(0.8);
}
.anti_rotation {
    transform: rotate(-50deg) scale(1);
}
.rotation img,
.anti_rotation img {
    width: 70%;
}
.sidebarBottomImgContainer {
    position: relative;
    bottom: 0;
    width: 100%;
}
.sidebarBottomImgContainer img {
    height: 250px;
}
