.success-border {
    border-color: #008000 !important;
}
.success__checkicon-container {
    display: flex;
    justify-content: center;
    & img {
        border: solid 5px;
        border-radius: 50%;
        max-width: 150px;
        padding: 10px;
    }
}
.primary {
    color: '#0098d1';
}

.form__form-group-file {
    width: 100% !important;
    // @media only screen and (min-width: 1201px ) {
    //     width: 55% !important;

    // }
    // @media only screen and (min-width: 1201px ) {
    //     width: 60% !important;

    // }
    // @media only screen and (min-width: 1400px ) {
    //     width: 80% !important;

    // }
}
.form__form-group-file .file__placeholder {
    width: 100% !important;
    border-radius: 10px !important;
}
.imgContainerSuccess {
    overflow: hidden;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 15px;
    img {
        width: 100%;
    }
}
