.radio-button__with-image_wrapper {
    cursor: pointer;
    max-width: 250px;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 5px;
    padding: 10%;
    width: fit-content;
    filter: grayscale(90%);
    opacity: .7;
    transition: all .2s ease;
    &.checked {
        opacity: 1;
        filter: grayscale(0);
        border-color: $primary-color;
    }
    .MuiFormControlLabel-root {
        margin: 0 !important;   // remove negative margin on label
    }
    .radio-button__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .radio-button__with-image_wrapper {
        padding: 10%;
        .MuiCheckbox-root {
            display: none !important    // Hide classic checkbox field
        }
    }
    .radio-button__with-image_label {
        text-align: center;
        display: block;
    }
}
